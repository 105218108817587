import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { IoIosCloseCircle } from "react-icons/io"
import { useMediaQuery } from '@mui/material';

export default function PdfModal({ pdfSrc, pdfAlt, open, handleClose }) {
  const isMobile = useMediaQuery('(max-width:735px)');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80vw' : '55vw',
    height: isMobile ? '80vh' : '95vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
  };
  
  const closeButtonStyle = {
    position: 'absolute',
    top: -7,
    right: -7,
    color: 'white'
  };

  return (
    <Modal
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 200,
          sx: {
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <IconButton
              sx={closeButtonStyle}
              onClick={handleClose}
              aria-label="close"
            >
              <IoIosCloseCircle />
          </IconButton>
          <Box
            component="iframe"
            sx={{
              width: '100%',
              height: '100%',
            }}
            src={pdfSrc}
            alt={pdfAlt}
          />
        </Box>
      </Fade>
    </Modal>
  );
}