import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Load from "./components/Load/Load";
import VideoLayout from "./Layouts/VideoLayout";
import { CaseStudy } from "./Pages/CaseStudy";

const HomePage = lazy(() => import("./Pages/HomePage"));
const TermsOfService = lazy(() => import("./Pages/TermsOfService"));
const Shopify = lazy(() => import("./Pages/Shopify"));
const Saas = lazy(() => import("./Pages/Saas"));
const MobileDev = lazy(() => import("./Pages/MobileDev"));
const Web3 = lazy(() => import("./Pages/Web3"));
const LowCodeDev = lazy(() => import("./Pages/LowCodeDev"));
const UserInterface = lazy(() => import("./Pages/UserInterface"));
const Ai = lazy(()=> import("./Pages/Ai"));

function App() {
  const routing = (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Load />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/terms-of-services"
        element={
          <Suspense fallback={<Load />}>
            <TermsOfService />
          </Suspense>
        }
      />

      <Route path="/" element={<VideoLayout />}>
        <Route
          path="/shopify"
          element={
            <Suspense fallback={<Load />}>
              <Shopify />
            </Suspense>
          }
        />

        <Route
          path="/software-as-a-service"
          element={
            <Suspense fallback={<Load />}>
              <Saas />
            </Suspense>
          }
        />

        <Route
          path="/ai-ml"
          element={
            <Suspense fallback={<Load />}>
              <Ai />
            </Suspense>
          }
        />

        <Route
          path="/mobile-development"
          element={
            <Suspense fallback={<Load />}>
              <MobileDev />
            </Suspense>
          }
        />

        <Route
          path="/web3"
          element={
            <Suspense fallback={<Load />}>
              <Web3 />
            </Suspense>
          }
        />

        <Route
          path="/low-code-development"
          element={
            <Suspense fallback={<Load />}>
              <LowCodeDev />
            </Suspense>
          }
        />

        <Route
          path="/user-interface"
          element={
            <Suspense fallback={<Load />}>
              <UserInterface />
            </Suspense>
          }
        />
        <Route
          path="/case-studies"
          element={
            <Suspense fallback={<Load />}>
              <CaseStudy />
            </Suspense>
          }
        />
      </Route>

    </Routes>
  );
  return <Router>{routing}</Router>;
}

export default App;
