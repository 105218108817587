import React from "react";
import "./BlackFooter.css";
import { Link } from "react-router-dom";

import Instagram from '../../Assets/instagram.svg';
import Linkedin from '../../Assets/linkedin.svg';
import Facebook from '../../Assets/facebook.svg';

function BlackFooter() {
    return (
        <>
            <footer className="BlackFooter">
                <p>
                    for Jobs & Internships mail us at{' '}
                    <a href="mailto:biraj@penguinapps.tech">
                        biraj@penguinapps.tech
                    </a>
                </p>
                <br />
               
                <div className="footer-social-links-container">
                    <a
                        className="footer-social-links"
                        target="_blank"
                        href="https://www.instagram.com/penguinapps.in/"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={Instagram}
                            alt="instagram"
                            className="footer-instagram"
                            loading="lazy"
                        />
                    </a>
                    <a
                        className="footer-social-links"
                        target="_blank"
                        href="https://www.linkedin.com/company/penguinapps/mycompany/"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={Linkedin}
                            alt="linkedin"
                            className="footer-linkedin"
                            loading="lazy"
                        />
                    </a>
                    <a
                        className="footer-social-links"
                        href="https://www.facebook.com/PenguinAppsMumbai"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={Facebook}
                            alt="facebook"
                            className="footer-facebook"
                            loading="lazy"
                        />
                    </a>
                </div>
                
                <p>&nbsp;</p>
                <div className="footer-links-container">
                    <Link to="/">
                        <p className="underline-text">HOME</p>
                    </Link>
                    <Link target="_blank" to="/case-studies">
                        <p className="underline-text">CASE STUDIES</p>
                    </Link>
                    <Link target="_blank" to="/terms-of-services">
                        <p className="underline-text">TERMS OF SERVICES</p>
                    </Link>
                </div>
                <p>&nbsp;</p>
                <p className="rights-text">©2025 PenguinApps, All rights reserved.</p>
            </footer>
        </>
    );
}

export default BlackFooter;
