import React from "react";
import "./WhiteFooter.css";

const WhiteFooter = () => {
  return (
    <div className="white-footer">
     <p> For more Company Portfolio, Case Studies, Business Enquiry, NDA, and RFPs
      mail us at <span><a href="mailto:info@penguinapps.tech">info@penguinapps.tech</a></span></p>
    </div>
  );
};

export default WhiteFooter;
