import React, { useState } from "react";
import NavMenu from "../../Assets/menu-icon.svg";
import CloseIcon from "../../Assets/cross-icon.svg";
import NavbarPenguin from '../../Assets/navbar-penguin-02.png';
import EmailArrow from '../../Assets/email-arrow.svg';
import Instgram from '../../Assets/instagram.svg';
import Facebook from '../../Assets/facebook.svg';
import Linkedin from '../../Assets/linkedin.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Navbar.css';

const Navbar = () => {
    React.useEffect(() => {
        AOS.init({ duration: 1200 });
    }, []);
    const [navOpen, setNavOpen] = useState(false);
    const [contactUs, setContactUs] = useState(false);
    const [showEmailArrow, setShowEmailArrow] = useState(false);
    const [showWaArrow, setShowWaArrow] = useState(false);
    const [showSocialArrow, setShowSocialArrow] = useState(false);

    const onContactClick = () => {
        setContactUs(true);
    };

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    const handleCloseClick = () => {
        document
            .querySelector('.close-text')
            .classList.add('tracking-out-contract');
        document
            .querySelector('.nav-close-btn')
            .classList.add('scale-out-center');
        document.querySelector('.navbar-component').classList.add('closing');
        // document.querySelector(".menu-text-container").classList.remove("fade-in");
        document.querySelector('.navbar-menu').classList.add('hide');
        document.querySelector('.navbarImg-container').classList.add('hide');
        setTimeout(() => {
            setNavOpen(false);
        }, 500);
    };

    const handleContactClose = () => {
        setContactUs(false);
        setTimeout(() => {
            document
            .querySelector('.close-text')
            .classList.add('tracking-out-contract');
            document
                .querySelector('.nav-close-btn')
                .classList.add('scale-out-center');
            document.querySelector('.navbar-component').classList.add('closing');
            // document.querySelector(".menu-text-container").classList.remove("fade-in");
            document.querySelector('.navbar-menu').classList.add('hide');
            document.querySelector('.navbarImg-container').classList.add('hide');
            setTimeout(() => {
                setNavOpen(false);
            }, 500);
        }, 100)
        
    }

    return (
        <div>
            {contactUs ? (
                <div
                    className="NavBtn nav-close-container"
                    onClick={handleContactClose}
                >
                    <p>CLOSE</p>
                    <div className="nav-close-btn scale-in-center">
                        <img src={CloseIcon} alt="nav-close" />
                    </div>
                </div>
            ) : navOpen ? (
                <div
                    className="NavBtn nav-close-container"
                    onClick={handleCloseClick}
                >
                    <p
                        className={`close-text ${
                            navOpen ? 'tracking-in-expand' : ''
                        }`}
                    >
                        CLOSE
                    </p>
                    <div
                        className={`nav-close-btn ${
                            navOpen ? 'scale-in-center' : ''
                        }`}
                    >
                        <img src={CloseIcon} alt="nav-close" />
                    </div>
                </div>
            ) : (
                <div className={`NavBtn`}>
                    <div onClick={toggleNav}>
                        <div className="nav-open-btn scale-in-center">
                            <img src={NavMenu} alt="nav-open" />
                        </div>
                    </div>
                </div>
            )}
            {navOpen && (
                <div className="navbar-component">
                    <div
                        className={`${
                            contactUs ? 'contact-us-content' : 'navbar-content'
                        }`}
                    >
                        <div className={`navbarImg-container`}>
                            {contactUs && (
                                <div className="contact-us-title">
                                    <p className="tracking-in-contract-bck">
                                        GOT A <br />
                                        REQUIREMENT? <br />
                                        TALK TO US!
                                    </p>
                                </div>
                            )}
                            {/* Yadav : Change this image to have 2025 text */}
                            <img
                                className={`${
                                    navOpen && 'fade-in'
                                } penguin-image`}
                                src={NavbarPenguin}
                                alt="navbar penguin growth"
                            />
                        </div>
                        {contactUs ? (
                            <div className="contact-us-menu">
                                <div className="contact-us-menu-container">
                                    <div
                                        className="contact-email"
                                        // data-aos="fade-up"
                                        onMouseOver={() =>
                                            setShowEmailArrow(true)
                                        }
                                        onMouseOut={() =>
                                            setShowEmailArrow(false)
                                        }
                                    >
                                        <div className="title-text tracking-in-contract-bck">
                                            EMAIL&nbsp;&nbsp;
                                            {showEmailArrow && (
                                                <img
                                                    className="scale-in-tl "
                                                    src={EmailArrow}
                                                    alt="arrow-down"
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="des-text"
                                            style={{ marginTop: '1rem' }}
                                        >
                                            <a
                                                target="_blank"
                                                href="mailto:info@penguinapps.tech"
                                                rel="noopener noreferrer"
                                                className="tracking-in-contract-bck"
                                            >
                                                info@penguinapps.tech
                                            </a>
                                        </div>
                                        <div className="hr-container email-hr">
                                            <hr />
                                        </div>
                                    </div>

                                    <div
                                        className="contact-whatsapp"
                                        // data-aos="fade-up"
                                        onMouseOver={() => setShowWaArrow(true)}
                                        onMouseOut={() => setShowWaArrow(false)}
                                    >
                                        <div className="title-text tracking-in-contract-bck">
                                            WHATSAPP&nbsp;&nbsp;
                                            {showWaArrow && (
                                                <img
                                                    className="scale-in-tl"
                                                    src={EmailArrow}
                                                    alt="arrow-down"
                                                />
                                            )}
                                        </div>
                                        <div
                                            className="des-text"
                                            style={{ marginTop: '1rem' }}
                                        >
                                            <a
                                                target="_blank"
                                                href="https://wa.me/+919987003228"
                                                rel="noopener noreferrer"
                                                className="tracking-in-contract-bck"
                                            >
                                                +91 99870 03228
                                            </a>
                                        </div>
                                        <div className="hr-container whatsapp-hr">
                                            <hr />
                                        </div>
                                    </div>

                                    <div
                                        className="contact-social"
                                        // data-aos="fade-up"
                                        onMouseOver={() =>
                                            setShowSocialArrow(true)
                                        }
                                        onMouseOut={() =>
                                            setShowSocialArrow(false)
                                        }
                                    >
                                        <div className="title-text tracking-in-contract-bck">
                                            SOCIAL LINKS&nbsp;&nbsp;
                                            {showSocialArrow && (
                                                <img
                                                    className="scale-in-tl"
                                                    src={EmailArrow}
                                                    alt="arrow-down"
                                                />
                                            )}
                                        </div>
                                        <div>
                                            <a
                                                className="social-links"
                                                target="_blank"
                                                href="https://www.instagram.com/penguinapps.in/"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={Instgram}
                                                    alt="instagram"
                                                />
                                            </a>
                                            <a
                                                className="social-links"
                                                target="_blank"
                                                href="https://www.linkedin.com/company/penguinapps/mycompany/"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={Linkedin}
                                                    alt="instagram"
                                                />
                                            </a>
                                            <a
                                                className="social-links"
                                                href="https://www.facebook.com/PenguinAppsMumbai"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img
                                                    src={Facebook}
                                                    alt="instagram"
                                                />
                                            </a>
                                        </div>

                                        <div className="hr-container social-hr">
                                            <hr />
                                        </div>
                                    </div>
                                    {/* <a
                    className="social-links"
                    href="https://twitter.com/birajghosh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Twitter} alt="instagram" />
                  </a> */}
                                </div>
                            </div>
                        ) : (
                            <div className="navbar-menu">
                                <div
                                    className={`menu-text-container  ${
                                        navOpen && 'fade-in'
                                    }`}
                                    onClick={handleCloseClick}
                                >
                                    <a href="/#about-us">ABOUT US</a>
                                </div>
                                <div
                                    className={`menu-text-container ${
                                        navOpen && 'fade-in'
                                    }`}
                                    onClick={handleCloseClick}
                                >
                                    <a href="/#services">SERVICES</a>
                                </div>
                                <div
                                    className={`menu-text-container ${
                                        navOpen && 'fade-in'
                                    }`}
                                    onClick={handleCloseClick}
                                >
                                    <a href="/#testimonials">TESTIMONIALS</a>
                                </div>
                                <div
                                    className={`menu-text-container ${
                                        navOpen && 'fade-in'
                                    }`}
                                    onClick={handleCloseClick}
                                >
                                    <a href="/case-studies">CASE STUDIES</a>
                                </div>
                                <div
                                    className={`menu-text-container ${
                                        navOpen && 'fade-in'
                                    }`}
                                    onClick={onContactClick}
                                >
                                    <a href="/#contact-us">CONTACT US</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
